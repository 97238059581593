@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    :root {
        --navbar-height: 80px;
    }

    html {
        @apply scroll-smooth;
        scroll-padding-top: var(--navbar-height);
    }

    body {
        @apply bg-gradient-to-br from-gray-50 to-gray-100 antialiased;
    }
}

@layer utilities {
    .section-spacing {
        @apply py-20 lg:py-28;
    }

    .animate-card-hover {
        @apply transition-all duration-300 hover:-translate-y-2 hover:shadow-xl;
    }

    .gradient-text {
        @apply bg-gradient-to-r from-blue-600 to-purple-600 bg-clip-text text-transparent;
    }
}

html {
    scroll-behavior: smooth;
}


@keyframes gradientAnimation {
    0% {
        background-position: 0% 50%;
    }
    50% {
        background-position: 100% 50%;
    }
    100% {
        background-position: 0% 50%;
    }
}

.animated-gradient {
    background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
    background-size: 400% 400%;
    animation: gradientAnimation 15s ease infinite;
}